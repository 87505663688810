import { environment } from '@environments/environment';

// https://www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-171.php
const parseCookie = window.parseCookie = str =>
  str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1]?.trim());
      return acc;
    }, {});

const cookies = parseCookie(document.cookie);

const redirectUrl = `${environment.authUrl}/checklogin?next_url=${encodeURIComponent(window.location.href)}`;

const access_token = cookies[`${environment.cookiePrefix}access_token`];

if (environment.env !== 'test') {
  if (!access_token) {
    window.location.href = redirectUrl;
  }

  const request = fetch(environment.apiUrl + 'asserttoken', {
    headers: {
      Authorization: 'Bearer ' + cookies[`${environment.cookiePrefix}access_token`]
    },
  });

  request.then(async (res) => {
    const data = await res.json();
    if (data.error) {
      window.location.href = redirectUrl;
    }
  });
}
